import React, { useCallback, useEffect, useState } from 'react';
import { ALLOW_INSURED_BIRDI_PRICE } from 'gatsby-env-variables';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import { graphql, navigate } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { TrackInputError } from 'util/google_optimize/optimize_helper';

//  state
import { CreditCardPayload } from 'state/account/account.services';
import {
    accountCreditCardsSelector,
    accountProfileAddressesSelector,
    accountHasInsuranceSelector,
    accountPlansSelector
} from 'state/account/account.selectors';
import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';
import {
    accountFetchHealthConditionsRoutine,
    accountFetchPlansRoutine,
    accountFetchProfileRoutine,
    accountGetAllCreditCardsRoutine
} from 'state/account/account.routines';

// Cart
import { cartOrderBillShipMethodSelector, cartOrderBillShipSelector, cartSelector } from 'state/cart/cart.selectors';
import { DEFAULT_SHIPPING_ID, EXPEDITED_SHIPPING_COST, EXPEDITED_SHIPPING_ID, processCart } from 'util/cart';

import {
    getCartRoutine,
    cartCompleteOrderRoutine,
    cartUpdatePaymentRoutine,
    cartUpdateShippingRoutine,
    cartUpdateExpeditedShippingRoutine
} from 'state/cart/cart.routines';

//  hooks
import { useHealthConditions } from 'hooks/useHealthConditions';

//  health profile
import {
    AllergiesModalContent,
    HealthConditionsModalContent
} from 'components/health-profile/health-profile.component';
import { HealthConditionPills } from 'components/health-conditions/health-conditions.component';
import { HealthConditionsWarningModalContent } from 'pages/secure/profile/health-profile';
import { HealthProfileBubbleUpdateEvent } from 'components/health-profile/health-profile.props';

//  workflow
import WorkflowLayout from 'components/layouts/workflow/workflow.layout';
import WorkflowLayoutFormWrapper from 'components/workflow-layout-form-wrapper/workflow-layout-form-wrapper.component';

//  shipping address
import { AddressCardProps } from 'components/shipping-addresses/address-card/address-card.props';
import ShippingAddresses from 'components/shipping-addresses/shipping-addresses.component';

//  payment
import PaymentMethods, { PaymentMethodsProps } from 'components/payment-methods/payment-methods.components';
import { medicineCabinetPrescriptionsSelector } from 'state/medicine-cabinet/medicine-cabinet.selectors';

// analytics
import { TrackCheckoutStep } from 'util/google_optimize/optimize_helper';

//  misc ui-kit
import Button from 'ui-kit/button/button';
import Spinner from 'ui-kit/spinner/spinner';
import BirdiAccordion from 'ui-kit/accordion/accordion';

import './review.style.scss';
import { OrderBillShip } from 'types/order-prescription';
import { medicineCabinetGetAllPrescriptions } from 'state/medicine-cabinet/medicine-cabinet.routines';
import UpdateCartModalContent, { FailureUpdateCartModalContent } from '../intra-page-items/_cart-update-modal-item';
import { ExtendedCartObjectPayload } from 'state/cart/cart.services';
import { drugDiscountPriceRoutine } from 'state/drug/drug.routines';
import { drugSelector } from 'state/drug/drug.selectors';
import { PrescriptionObjectPayload } from 'state/medicine-cabinet/medicine-cabinet.services';
import { CartTotal } from 'components/cart/review/cart-total.component';
import { PrescriptionInformation } from 'components/cart/review/prescription-information.component';
import { CartShipping } from 'components/cart/cart-shipping.component';
import { CartReviewSectionHeader } from 'components/cart-review-section-header';
import CartIcon from 'ui-kit/icons/cart-icon/cart-icon';
import PageSection from 'ui-kit/page-section/page-section';

//
// --- ReviewOrder Component ---

const ReviewOrder = ({ data }: { data: GatsbyTypes.CartReviewDataQuery }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const prescriptionsObject = useSelector(medicineCabinetPrescriptionsSelector);
    const accountPlans = useSelector(accountPlansSelector);
    const cartObject = useSelector(cartSelector);
    const [hasUnknownPrice, setHasUnknownPrice] = useState(false);
    const [extendedCartObject, setExtendedCartObject] = useState<ExtendedCartObjectPayload | undefined>(undefined);
    const accountHasInsurance = useSelector(accountHasInsuranceSelector);
    const { existingAllergies, existingConditions, userHasNotSubmittedAllergies, userHasNotSubmittedConditions } =
        useHealthConditions();

    const [initialOrderPrice, setInitialOrderPrice] = useState(extendedCartObject?.orderTotal);
    const [currentShippingPrice, setCurrentShippingPrice] = useState(0);
    const [errorStatus, setErrorStatus] = useState(false);
    const [cartIsEmpty, setCartIsEmpty] = useState(true);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isCartBusy, setIsCartBusy] = useState(false);
    const [cartPaymentCardSeqNumValid, setCartPaymentCardSeqNumValid] = useState(false);
    const { drugDiscountPrices } = useSelector(drugSelector);
    const [drugDiscountPricesFetched, setDrugDiscountPricesFetched] = useState(false);
    const [cartPageTracked, setCartPageTracked] = useState(false);
    const shipMethodId = useSelector(cartOrderBillShipMethodSelector);
    let birdiPricePrescriptions: PrescriptionObjectPayload[] = [];

    useEffect(() => {
        dispatch(accountGetAllCreditCardsRoutine.trigger());
        dispatch(accountFetchProfileRoutine.trigger());
        dispatch(accountFetchHealthConditionsRoutine.trigger());
        if (cartObject === undefined) {
            dispatch(getCartRoutine.trigger());
        }
        dispatch(medicineCabinetGetAllPrescriptions({ showNewRxModal: false }));
        if (!accountPlans || accountPlans.length === 0) {
            dispatch(accountFetchPlansRoutine());
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getDrugDiscountPrice = async (birdiPricePrescriptions: PrescriptionObjectPayload[]) =>
        new Promise((resolve, reject) => {
            // do anything here
            dispatch(
                drugDiscountPriceRoutine.trigger({
                    prescriptions: birdiPricePrescriptions,
                    forceBirdiInsurance: true,
                    location: 'Cart',
                    onSuccess: () => {
                        resolve('success');
                    }
                })
            );
        });

    const trackCartStep2 = () => {
        if (!cartPageTracked) {
            TrackCheckoutStep({
                stepName: 'checkout',
                step: '2',
                cart: extendedCartObject,
                prescriptions: prescriptionsObject,
                t: t,
                shippingCost: cartObject?.orderHeader?.orderHighPriority ? `${EXPEDITED_SHIPPING_COST}` : '0',
                accountHasInsurance: accountHasInsurance
            });
            setCartPageTracked(true);
        }
    };

    useEffect(() => {
        const cartItemsObject = extendedCartObject?.extendedRefillRxs;

        if (cartItemsObject?.length) {
            if (!drugDiscountPricesFetched && accountHasInsurance && ALLOW_INSURED_BIRDI_PRICE) {
                // Get The Birdi Price
                cartItemsObject.forEach((item: any) => {
                    const currentPrescription = prescriptionsObject.find((obj: any) => {
                        return obj.rxNumber === item.rxNumber;
                    });
                    if (currentPrescription) {
                        birdiPricePrescriptions.push(currentPrescription);
                    }
                });
                getDrugDiscountPrice(birdiPricePrescriptions).then(() => {
                    setDrugDiscountPricesFetched(true);
                    trackCartStep2();
                });
            } else {
                if (prescriptionsObject.length > 0 && accountPlans && accountPlans.length > 0) {
                    trackCartStep2();
                }
            }
            setCartIsEmpty(false);
        } else {
            setCartIsEmpty(true);
            if (extendedCartObject) {
                trackCartStep2();
            }
        }

        setHasUnknownPrice(extendedCartObject?.itemHasUnknownPrice ?? false);
    }, [extendedCartObject]);

    useEffect(() => {
        if (cartObject) {
            const extendedCart = processCart(
                cartObject,
                accountHasInsurance,
                prescriptionsObject,
                drugDiscountPrices,
                accountPlans
            );
            setExtendedCartObject(extendedCart);
            setInitialOrderPrice(extendedCart.orderTotal);
            setCurrentShippingPrice(extendedCart?.orderHeader?.orderHighPriority ? EXPEDITED_SHIPPING_COST : 0);
        }
    }, [cartObject, accountHasInsurance, prescriptionsObject, drugDiscountPrices, accountPlans]);

    useEffect(() => {
        if (errorStatus) {
            TrackInputError(null, 'Form Submit', t('pages.reviewOrder.errors.orderError'), 'ReviewOrder');
        }
    }, [errorStatus]);

    const allPaymentData = useSelector(accountCreditCardsSelector);

    const profileAddresses = useSelector(accountProfileAddressesSelector);
    const orderBillShip = useSelector(cartOrderBillShipSelector);
    const addresses: AddressCardProps[] = profileAddresses.map((address) => {
        const cartCurrentShippingSeqNum = orderBillShip?.patientShipAddressSeq;
        const isCurrentCartShippingAddress = address.addressSeqNum === cartCurrentShippingSeqNum;
        return {
            addressSeqNum: address.addressSeqNum,
            defaultAddress: address.defaultShip,
            isChecked: isCurrentCartShippingAddress,
            address1: address.address1,
            address2: address.address2,
            city: address.city,
            country: address.country,
            state: address.state,
            zipcode: address.zipcode,
            zipcodeFour: address.zipcodeFour,
            defaultAddressLabel: t('shipping.shipToThisAddressLabel'),
            addressType: address.addressTypeDesc,
            isProfile: false
        };
    });

    useEffect(() => {
        if (allPaymentData && orderBillShip) {
            if (!orderBillShip.paymentCardSeqNum) {
                // Set the cardSeqNum to the default card and update
                let defaultCard: CreditCardPayload | undefined = undefined;
                if (allPaymentData !== undefined && allPaymentData.length > 0) {
                    if (allPaymentData.length === 1) {
                        defaultCard = allPaymentData[0];
                    } else {
                        defaultCard = allPaymentData.find((obj: { defaultCard: any }) => obj.defaultCard);
                    }
                }
                if (defaultCard) {
                    const updatedPayment = {
                        ...orderBillShip,
                        paymentCardSeqNum: defaultCard.cardSeqNum
                    };
                    dispatch(
                        cartUpdatePaymentRoutine.trigger({
                            ...updatedPayment
                        })
                    );
                }
            }
        }

        if (allPaymentData?.length && orderBillShip?.paymentCardSeqNum) {
            const selectedCard = allPaymentData.find((card) => card.cardSeqNum === orderBillShip.paymentCardSeqNum);
            if (selectedCard) {
                setCartPaymentCardSeqNumValid(true);
            } else {
                setCartPaymentCardSeqNumValid(false);
            }
        } else {
            setCartPaymentCardSeqNumValid(false);
        }
    }, [allPaymentData, orderBillShip]);

    const handleUpdateConditionsClick = ({ action, update }: HealthProfileBubbleUpdateEvent) => {
        dispatch(
            openModal({
                showClose: true,
                className: 'prescription-modal',
                bodyContent: <HealthConditionsWarningModalContent translation={t} />,
                ctas: [
                    {
                        label: t('modals.healthConditions.submit'),
                        variant: 'primary',
                        onClick: () => {
                            dispatch(closeModal({}));
                            dispatch(action(update));
                        },
                        dataGALocation: 'ReviewOrderHealthConditions'
                    }
                ]
            })
        );
    };
    const handleToggleHealthConditionsClick = () => {
        dispatch(
            openModal({
                showClose: true,
                className: 'prescription-modal',
                bodyContent: (
                    <HealthConditionsModalContent
                        title={t('modals.prescription.addHealthCondition.title')}
                        subTitle={t('modals.prescription.addHealthCondition.subTitle', {
                            phoneNumber: t('modals.healthConditions.phoneNumber')
                        })}
                        onUpdateHealthConditions={handleUpdateConditionsClick}
                        submitLabel={t('modals.prescription.addHealthCondition.submit')}
                    />
                ),
                ctas: []
            })
        );
    };
    const handleToggleAllergiesClick = () => {
        dispatch(
            openModal({
                showClose: true,
                className: 'prescription-modal',
                bodyContent: (
                    <AllergiesModalContent
                        title={t('modals.prescription.addAllergy.title')}
                        subTitle={t('modals.prescription.addAllergy.subTitle', {
                            phoneNumber: t('modals.healthConditions.phoneNumber')
                        })}
                        onUpdateHealthConditions={handleUpdateConditionsClick}
                        freeformConditionsLabel={t('components.healthConditions.labels.freeformAllergiesLabel')}
                        submitLabel={t('modals.prescription.addAllergy.submit')}
                    />
                ),
                ctas: []
            })
        );
    };

    const handleShipToAddressClick = (address: AddressCardProps) => {
        const editAddress = profileAddresses.find(
            (location) =>
                location.address1 === address.address1 &&
                location.address2 === address.address2 &&
                location.city === address.city &&
                location.state === address.state &&
                location.zipcode === address.zipcode + ''
        );
        const updatedAddress = {
            ...orderBillShip,
            patientBillAddressSeq: editAddress?.addressSeqNum,
            patientShipAddressSeq: editAddress?.addressSeqNum
        };
        setIsCartBusy(true);
        dispatch(
            cartUpdateShippingRoutine.trigger({
                ...updatedAddress,
                onSuccess: () => {
                    // Update the addresses
                    addresses.forEach((address) => {
                        address.isChecked = address.addressSeqNum === editAddress?.addressSeqNum;
                    });
                    setIsCartBusy(false);
                    dispatch(
                        openModal({
                            showClose: true,
                            className: 'prescription-modal',
                            bodyContent: <UpdateCartModalContent area={t('modals.updateCart.areas.address')} />,
                            ctas: [
                                {
                                    label: t('modals.updateCart.labels.gotIt'),
                                    variant: 'primary',
                                    onClick: () => {
                                        dispatch(closeModal({}));
                                    },
                                    dataGALocation: 'ReviewOrderUpdateCart'
                                }
                            ]
                        })
                    );
                },
                onFailure: () => {
                    setIsCartBusy(false);
                    dispatch(
                        openModal({
                            showClose: true,
                            className: 'prescription-modal',
                            bodyContent: <FailureUpdateCartModalContent area={t('modals.updateCart.areas.address')} />,
                            ctas: [
                                {
                                    label: t('modals.updateCart.labels.gotIt'),
                                    variant: 'primary',
                                    onClick: () => {
                                        dispatch(closeModal({}));
                                    },
                                    dataGALocation: 'ReviewOrderUpdateCartError'
                                }
                            ]
                        })
                    );
                }
            })
        );
    };

    const handlePaymentMethodSelectionChange = React.useCallback<
        NonNullable<PaymentMethodsProps['onCardSelectionChange']>
    >(
        (newSelectedCard) => {
            if (!newSelectedCard || !orderBillShip) {
                return;
            }

            const updatedOrderBillShip: OrderBillShip = {
                ...orderBillShip,
                paymentCardSeqNum: newSelectedCard.cardSeqNum
            };

            dispatch(
                cartUpdatePaymentRoutine.trigger({
                    ...updatedOrderBillShip,
                    onSuccess: () => {
                        dispatch(
                            openModal({
                                showClose: true,
                                className: 'prescription-modal',
                                bodyContent: <UpdateCartModalContent area={t('modals.updateCart.areas.payment')} />,
                                ctas: [
                                    {
                                        label: t('modals.updateCart.labels.gotIt'),
                                        variant: 'primary',
                                        onClick: () => {
                                            dispatch(closeModal({}));
                                        },
                                        dataGALocation: 'ReviewOrderUpdateCart'
                                    }
                                ]
                            })
                        );
                    },
                    onFailure: () => {
                        dispatch(
                            openModal({
                                showClose: true,
                                className: 'prescription-modal',
                                bodyContent: (
                                    <FailureUpdateCartModalContent area={t('modals.updateCart.areas.payment')} />
                                ),
                                ctas: [
                                    {
                                        label: t('modals.updateCart.labels.gotIt'),
                                        variant: 'primary',
                                        onClick: () => {
                                            dispatch(closeModal({}));
                                        },
                                        dataGALocation: 'ReviewOrderUpdateCartError'
                                    }
                                ]
                            })
                        );
                    }
                })
            );
        },
        [dispatch, orderBillShip, t]
    );

    const handleSubmitOrderClick = () => {
        setErrorStatus(false);
        setIsSubmitting(true);
        dispatch(
            cartCompleteOrderRoutine({
                orderTotal: String(Number(initialOrderPrice) + Number(currentShippingPrice)),
                lineItems: extendedCartObject?.extendedRefillRxs,
                onSuccess: () => {
                    setIsSubmitting(false);
                    TrackCheckoutStep({
                        stepName: 'purchase',
                        step: '1',
                        cart: extendedCartObject,
                        prescriptions: prescriptionsObject,
                        t: t,
                        shippingCost: extendedCartObject?.orderHeader?.orderHighPriority
                            ? `${EXPEDITED_SHIPPING_COST}`
                            : '0',
                        accountHasInsurance: accountHasInsurance
                    });
                    navigate('/secure/cart/order-confirmation');
                },
                onFailure: () => {
                    setIsSubmitting(false);
                    dispatch(getCartRoutine.trigger());
                    setErrorStatus(true);
                }
            })
        );
    };

    const showShippingError = useCallback(() => {
        dispatch(
            openModal({
                showClose: true,
                className: 'prescription-modal',
                bodyContent: <FailureUpdateCartModalContent area={t('modals.updateCart.areas.shippingOption')} />,
                ctas: [
                    {
                        label: t('modals.updateCart.labels.gotIt'),
                        variant: 'primary',
                        onClick: () => {
                            dispatch(closeModal({}));
                        },
                        dataGALocation: 'UpdateCartError'
                    }
                ]
            })
        );
    }, [dispatch, t]);

    const handleDefaultShippingClick = () => {
        dispatch(
            cartUpdateExpeditedShippingRoutine({
                orderHighPriority: false,
                shipMethodId: DEFAULT_SHIPPING_ID,
                onSuccess: () => {
                    dispatch(getCartRoutine.trigger());
                    setCurrentShippingPrice(0);
                },
                onFailure: () => {
                    showShippingError();
                    dispatch(getCartRoutine.trigger());
                    return false;
                }
            })
        );
    };

    const handleExpeditedShippingClick = () => {
        dispatch(
            cartUpdateExpeditedShippingRoutine({
                orderHighPriority: true,
                shipMethodId: EXPEDITED_SHIPPING_ID,
                onSuccess: () => {
                    dispatch(getCartRoutine.trigger());
                    setCurrentShippingPrice(EXPEDITED_SHIPPING_COST);
                },
                onFailure: () => {
                    showShippingError();
                    dispatch(getCartRoutine.trigger());
                    return false;
                }
            })
        );
    };

    const HealthProfileHeaderText = () => {
        return (
            <div className="health-profile-subtitle">
                {t('pages.reviewOrder.healthProfileConfirmation')} <strong>1-877-668-4987</strong>.
            </div>
        );
    };

    const defaultCreditCard = React.useMemo(() => {
        return Array.isArray(allPaymentData) && allPaymentData.length > 0
            ? allPaymentData.find((card) => card.defaultCard)
            : undefined;
    }, [allPaymentData]);

    return (
        <WorkflowLayout
            className="cart-review-workflow"
            backgroundImage={data.backgroundImage}
            metaData={{ nodeTitle: t('pages.reviewOrder.title') }}
        >
            {!cartIsEmpty ? (
                <WorkflowLayoutFormWrapper
                    eyebrowText={t('pages.reviewOrder.eyebrowText')}
                    title={t('pages.reviewOrder.title')}
                    className="cart-review-workflow-layout-form-wrapper"
                    icon={<CartIcon className={'header-icon-container'} />}
                >
                    <Spinner isVisible={isCartBusy} t={t} />
                    <CartReviewSectionHeader label={t('components.medicineCabinetCart.shippingMethod.title')} />
                    <CartShipping
                        handleDefaultShippingClick={handleDefaultShippingClick}
                        handleExpeditedShippingClick={handleExpeditedShippingClick}
                        shipMethodId={shipMethodId}
                        t={t}
                    />
                    <CartReviewSectionHeader
                        label={t('pages.cart.prescriptionInformation')}
                        pricingLabel={t('pages.cart.pricing')}
                    />
                    <PrescriptionInformation
                        t={t}
                        fullCart={extendedCartObject}
                        prescriptions={prescriptionsObject}
                        accountHasInsurance={accountHasInsurance}
                        setCartPageTracked={setCartPageTracked}
                    />
                    <CartTotal
                        t={t}
                        hasUnknownPrice={hasUnknownPrice}
                        initialOrderPrice={initialOrderPrice}
                        currentShippingPrice={currentShippingPrice}
                        accountHasInsurance={accountHasInsurance}
                    />
                    <CartReviewSectionHeader label={t('pages.profile.healthProfile.labels.shippingAddress')} />
                    <ShippingAddresses
                        addressData={addresses}
                        onSetAsShipping={handleShipToAddressClick}
                        isProfile={false}
                        showLabels={true}
                        addNewAddressButtonLabel={t('pages.reviewOrder.addAddress')}
                        buttonVariant="text-blue"
                    />
                    <CartReviewSectionHeader label={t('pages.profile.healthProfile.labels.paymentInformation')} />
                    <PaymentMethods
                        addNewPaymentButtonLabel={t('pages.reviewOrder.addPayment')}
                        buttonVariant="text-blue"
                        isProfile={false}
                        showLabel={true}
                        onCardSelectionChange={handlePaymentMethodSelectionChange}
                        paymentData={allPaymentData ? allPaymentData : []}
                        paymentRequiredMessage={t('pages.reviewOrder.paymentMethodRequired')}
                        selectedCardSeqNum={orderBillShip?.paymentCardSeqNum || defaultCreditCard?.cardSeqNum}
                        showSelectCardRadioInput
                        showSetDefaultLink
                    />
                    <CartReviewSectionHeader
                        label={t('pages.profile.healthProfile.labels.healthProfile')}
                        text={<HealthProfileHeaderText />}
                    />
                    <Container fluid className="mt-5">
                        <Row>
                            <Col>
                                <h4 className="text-slate font-weight-bold health-conditions-title">
                                    {t('accordions.healthConditions.title')}
                                </h4>
                                <HealthConditionPills conditions={existingConditions} />
                            </Col>
                        </Row>
                        {userHasNotSubmittedConditions && (
                            <Row>
                                <Col>
                                    <div className="error">{t('pages.reviewOrder.errors.conditionsRequired')}</div>
                                </Col>
                            </Row>
                        )}
                        <Row className="mb-2">
                            <Col>
                                <Button
                                    plusIcon
                                    IconType="secondary"
                                    className="p-0"
                                    dataGAFormName="healthConditions"
                                    label={t('components.medicineCabinetCart.healthProfile.addHealthConditions')}
                                    onClick={handleToggleHealthConditionsClick}
                                    type="button"
                                    variant="text-blue"
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <BirdiAccordion.Spacer />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <h4 className="text-slate font-weight-bold allergies-title">
                                    {t('accordions.allergies.title')}
                                </h4>
                                <HealthConditionPills conditions={existingAllergies} />
                            </Col>
                        </Row>
                        {userHasNotSubmittedAllergies && (
                            <Row>
                                <Col>
                                    <div className="error">{t('pages.reviewOrder.errors.allergiesRequired')}</div>
                                </Col>
                            </Row>
                        )}
                        <Row className="mb-2">
                            <Col>
                                <Button
                                    plusIcon
                                    IconType="secondary"
                                    className="p-0"
                                    dataGAFormName="allergies"
                                    label={t('accordions.allergies.toggleText')}
                                    onClick={handleToggleAllergiesClick}
                                    type="button"
                                    variant="text-blue"
                                />
                            </Col>
                        </Row>
                    </Container>
                    <Row className="pt-5">
                        <Col>
                            {!!errorStatus && (
                                <div className="text has-errors">
                                    <div className="text-errors text-center">
                                        {t('pages.reviewOrder.errors.orderError')}
                                    </div>
                                </div>
                            )}
                        </Col>
                    </Row>
                    <Row className="pt-1 justify-content-center justify-content-md-end">
                        <Col xs={{ span: 12, order: 'first' }} md={{ span: 12, order: 'first' }}>
                            <div className="h6 font-weight-lighter health-profile--footer-info">
                                {t('pages.reviewOrder.note')}
                            </div>
                        </Col>
                        <Col
                            xs={{ span: 12, order: '2' }}
                            md={{ span: 'auto', order: 'last' }}
                            className="d-flex flex-column justify-content-md-start align-items-center align-items-md-end m-3"
                        >
                            <Button
                                type="button"
                                className="cart-submit-button"
                                label={t('pages.reviewOrder.submit')}
                                onClick={handleSubmitOrderClick}
                                dataGAFormName="OrderPrescription"
                                dataGAFormStepName="Checkout"
                                disabled={
                                    orderBillShip?.paymentCardSeqNum === null ||
                                    orderBillShip?.patientBillAddressSeq === null ||
                                    orderBillShip?.patientShipAddressSeq === null ||
                                    !cartPaymentCardSeqNumValid ||
                                    userHasNotSubmittedConditions ||
                                    userHasNotSubmittedAllergies ||
                                    isSubmitting
                                }
                            />
                        </Col>
                        <Col
                            className="d-flex justify-content-center justify-content-md-end"
                            xs={{ span: 12, order: 'last' }}
                            md={{ span: 'auto', order: '2' }}
                        >
                            <Button
                                type="button"
                                variant="text"
                                className="mt-3 mt-md-0 p-0 text-cerulean"
                                label={t('pages.reviewOrder.backToCart')}
                                onClick={() => {
                                    navigate('/secure/cart');
                                }}
                                dataGAFormName="OrderPrescription"
                                dataGAFormStepName="Checkout"
                            />
                        </Col>
                    </Row>
                </WorkflowLayoutFormWrapper>
            ) : (
                <PageSection className="page-section__white">
                    <Container fluid>
                        <Row className="justify-content-center">
                            <Col
                                className="text-center d-flex flex-column justify-content-center align-items-center"
                                xs={12}
                                md={6}
                            >
                                <CartIcon className={'cart-icon__circle'} />
                                <h1>{t('pages.reviewOrder.emptyCart.title')}</h1>
                                <h3>{t('pages.reviewOrder.emptyCart.subtitle')}</h3>
                                <div className="spacer" />
                                <Button
                                    type="button"
                                    className="sm-full"
                                    label={t('pages.reviewOrder.emptyCart.submit')}
                                    onClick={() => navigate('/secure/medicine-cabinet')}
                                    dataGALocation="Your shopping cart is empty."
                                />
                            </Col>
                        </Row>
                    </Container>
                </PageSection>
            )}
        </WorkflowLayout>
    );
};

export default ReviewOrder;

export const query = graphql`
    query CartReviewData($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        backgroundImage: file(relativePath: { eq: "assets/images/white-feathers-background.jpg" }) {
            id
            childImageSharp {
                gatsbyImageData(formats: [AUTO])
            }
        }
        successModalPillImage: file(relativePath: { eq: "assets/images/order-success-pill.jpg" }) {
            id
            childImageSharp {
                gatsbyImageData(formats: [AUTO])
            }
        }
        successModalPillImageMobile: file(relativePath: { eq: "assets/images/order-success-pill.jpg" }) {
            id
            childImageSharp {
                gatsbyImageData(formats: [AUTO])
            }
        }
    }
`;
