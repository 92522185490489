import React, { MutableRefObject } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import classNames from 'classnames';

import './payment-card.style.scss';
import { noop } from 'util/function';
import { ButtonProps } from 'ui-kit/button/button.props';
import Button from 'ui-kit/button/button';
import CreditCardIcon from 'ui-kit/icons/creditcard-icon/creditcard-icon';
import { ellipsify, lowercaseAndCapitalize } from 'util/cart';

//
// --- Types ---

type HTMLInputChange = React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
>['onChange'];

export interface PaymentCardProps {
    cardHolder: string;
    cardType: string;
    endingDigits: string;
    expiryMonth: string;
    expiryYear: string;
    isDefaultCard?: boolean;
    isSelectCardRadioInputChecked?: boolean;
    onRemoveCardLinkClick?: ButtonProps['onClick'];
    onSelectCardRadioInputChange?: HTMLInputChange;
    onSetDefaultClick?: ButtonProps['onClick'];
    removeCardLinkClassName?: string;
    showLabel?: boolean;
    showRemoveCardLink?: boolean;
    showSelectCardRadioInput?: boolean;
    showSetDefaultLink?: boolean;
    isInnerWidthViewport?: MutableRefObject<boolean>;
}

//
// --- PaymentCard Component ---

const PaymentCard: React.FC<PaymentCardProps> = (props) => {
    const {
        cardHolder,
        cardType,
        endingDigits,
        expiryMonth,
        expiryYear,
        isDefaultCard = false,
        isSelectCardRadioInputChecked = false,
        onRemoveCardLinkClick = noop,
        onSelectCardRadioInputChange = noop,
        onSetDefaultClick = noop,
        removeCardLinkClassName,
        showLabel = true,
        showRemoveCardLink = false,
        showSelectCardRadioInput = false,
        showSetDefaultLink = false,
        isInnerWidthViewport
    } = props;

    const { t } = useTranslation();

    const handleSelectCardRadioInputClick = React.useCallback<NonNullable<HTMLInputChange>>(
        (event) => {
            onSelectCardRadioInputChange(event);
            event.currentTarget.blur();
        },
        [onSelectCardRadioInputChange]
    );

    const formattedExpiryMonth = parseInt(expiryMonth) < 10 ? `0${expiryMonth}` : expiryMonth;

    const showActionLinkSection = showRemoveCardLink || showSetDefaultLink;
    const showActionLinkSeparator = showRemoveCardLink && showSetDefaultLink;

    return (
        <Col className="mb-4 flex-fill">
            <div className="payment-card border rounded rounded-2 border-sea-foam h-100">
                <Row className="d-flex pl-3 pr-3">
                    {showSelectCardRadioInput && (
                        <Col xs={2} className="p-3">
                            <section className="payment-card--default-payment mt-4">
                                <label className="default-payment text-slate mb-0 d-flex">
                                    <input
                                        checked={isSelectCardRadioInputChecked}
                                        data-input-type="profile-payment-option"
                                        name="payment-option"
                                        onChange={handleSelectCardRadioInputClick}
                                        type="radio"
                                    />
                                </label>
                            </section>
                        </Col>
                    )}
                    <Col xs={showSelectCardRadioInput ? 10 : 12} className="p-3">
                        <Row className="payment-card--content h-100">
                            <Col xs={12} className={'align-self-start'}>
                                <div className="d-flex justify-content-between mb-2">
                                    {showLabel && (
                                        <p className="payment-card__type align-self-start">
                                            <small>
                                                {t(
                                                    `pages.profile.payment.${
                                                        isDefaultCard ? 'primaryPayment' : 'secondaryPayment'
                                                    }`
                                                )}
                                            </small>
                                        </p>
                                    )}
                                </div>
                                <div className="d-flex flex-row justify-content-between">
                                    <div>
                                        <div className="h6 hero-eyebrow-text">
                                            <span className="text-capitalize d-inline-block card-type">{`${
                                                isInnerWidthViewport?.current
                                                    ? ellipsify(lowercaseAndCapitalize(cardType))
                                                    : cardType
                                            } ···· ${endingDigits}`}</span>
                                        </div>
                                        <div className="h6 hero-eyebrow-text">{cardHolder}</div>
                                        <div className="h6 hero-eyebrow-text">
                                            {t('pages.profile.payment.expiry', {
                                                month: formattedExpiryMonth,
                                                year: expiryYear
                                            })}
                                        </div>
                                    </div>
                                    <CreditCardIcon className="payment-card__credit-card-icon" variant={cardType} />
                                </div>
                            </Col>
                            <Col xs={12} className="align-self-end">
                                {showActionLinkSection && (
                                    <section className="payment-card--default-payment mt-4 d-flex">
                                        {showRemoveCardLink && (
                                            <Button
                                                className={classNames(
                                                    'px-0 pt-0 no-min-width',
                                                    removeCardLinkClassName
                                                )}
                                                label={t('shipping.removeAddress')}
                                                onClick={onRemoveCardLinkClick}
                                                type="button"
                                                variant="text"
                                            />
                                        )}
                                        {showActionLinkSeparator && <span className="divider">|</span>}
                                        {showSetDefaultLink && isDefaultCard && (
                                            <span className="strong px-0 pt-0 no-min-width">
                                                {t('pages.profile.payment.labels.defaultPayment')}
                                            </span>
                                        )}
                                        {showSetDefaultLink && !isDefaultCard && (
                                            <Button
                                                className="px-0 pt-0 no-min-width"
                                                dataGAFormName="address-card"
                                                label={t('pages.profile.payment.labels.setAsDefault')}
                                                onClick={onSetDefaultClick}
                                                type="button"
                                                variant="text"
                                            />
                                        )}
                                    </section>
                                )}
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </Col>
    );
};

export default PaymentCard;
