import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import './index.style.scss';
import { CartReviewSectionHeaderProps } from './types';

export const CartReviewSectionHeader: React.FC<CartReviewSectionHeaderProps> = ({
    label,
    text,
    pricingLabel,
    className = 'cart-review-section-header'
}) => {
    return (
        <Container fluid>
            <Row>
                <Col>
                    <div className={`${className} d-flex align-items-center justify-content-between pb-2 mb-3 mt-5`}>
                        <div className="d-flex align-items-center">
                            <div className="blue-half-pill mr-2" style={{ height: '0.8rem', width: '0.8rem' }} />
                            <div className="cart-review-section-title">{label}</div>
                        </div>
                        {pricingLabel && <div className="d-none d-lg-block">{pricingLabel}</div>}
                    </div>
                </Col>
            </Row>
            {text && (
                <Row>
                    <Col className="mt-4">{text}</Col>
                </Row>
            )}
        </Container>
    );
};
