import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import { noop } from 'util/function';
import {
    accountGetAllCreditCardsRoutine,
    accountRemoveCreditCardRoutine,
    accountUpdateCreditCardRoutine
} from 'state/account/account.routines';
import { CreditCardPayload } from 'state/account/account.services';
import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';
import UpdateProfileModalContent, {
    FailureUpdateProfileModalContent
} from 'pages/secure/profile/intra-page-items/_profile-update-modal.item';
import BirdiModalContent, { BirdiModalContentAlt } from 'components/birdi-modal/BirdiModalContent/BirdiModalContent';
import { BirdiModalHeaderDanger } from 'components/birdi-modal/birdi-modal-header';
import Button from 'ui-kit/button/button';
import { ButtonProps, ButtonVariantProps } from 'ui-kit/button/button.props';
import PaymentCard, { PaymentCardProps } from './payment-card/payment-card.component';
import PaymentForm from 'pages/secure/profile/intra-page-items/_payment.item';
import useWindowDimensions from 'hooks/useWindowDimensions';

//
// --- Types ---

export interface PaymentMethodsProps
    extends Pick<
        PaymentCardProps,
        | 'removeCardLinkClassName'
        | 'showLabel'
        | 'showRemoveCardLink'
        | 'showSetDefaultLink'
        | 'showSelectCardRadioInput'
    > {
    addNewPaymentButtonLabel?: string;
    buttonVariant?: typeof ButtonVariantProps;
    onCardRemoved?: (removedCard: CreditCardPayload) => void;
    onCardSelectionChange?: (newSelectedCard: CreditCardPayload) => void;
    onDefaultCardChanged?: (newDefaultCard: CreditCardPayload) => void;
    paymentData: CreditCardPayload[];
    paymentRequiredMessage?: string;
    selectedCardSeqNum?: string;
    isProfile?: boolean;
}

interface UpdatePrimaryCreditCardOptions {
    showPrimaryPaymentUpdatedModal?: boolean;
}

//
// --- PaymentMethods Component ---

const PaymentMethods: React.FC<PaymentMethodsProps> = (props) => {
    const {
        addNewPaymentButtonLabel,
        buttonVariant = 'primary',
        onCardRemoved = noop,
        onCardSelectionChange = noop,
        onDefaultCardChanged = noop,
        paymentData,
        paymentRequiredMessage,
        removeCardLinkClassName,
        selectedCardSeqNum,
        showLabel = false,
        showRemoveCardLink = false,
        showSelectCardRadioInput = false,
        showSetDefaultLink = false,
        isProfile = true
    } = props;

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { width } = useWindowDimensions();
    const isInnerWidthViewportRef = React.useRef<boolean>(false);

    const updatePrimaryCreditCard = React.useCallback(
        (newPrimaryCard: CreditCardPayload, opts: UpdatePrimaryCreditCardOptions = {}) => {
            const { showPrimaryPaymentUpdatedModal = false } = opts;

            if (!newPrimaryCard) {
                return;
            }

            dispatch(
                accountUpdateCreditCardRoutine.trigger({
                    ...newPrimaryCard,
                    onSuccess: () => {
                        dispatch(
                            openModal({
                                showClose: true,
                                bodyContent: showPrimaryPaymentUpdatedModal ? (
                                    <BirdiModalContent
                                        icon={'success'}
                                        title={t('modals.updateProfile.title')}
                                        body={t('modals.primaryPaymentMethodUpdated.body', {
                                            paymentMethod: `<strong>${t('pages.profile.payment.cardTypeAndNum', {
                                                cardNumber: newPrimaryCard.secureCardNumber.slice(-4),
                                                cardType: newPrimaryCard?.cardType
                                            })}</strong>`
                                        })}
                                    />
                                ) : (
                                    <UpdateProfileModalContent area={t('modals.updateProfile.areas.payment')} />
                                ),
                                onClose: () => {
                                    dispatch(accountGetAllCreditCardsRoutine.trigger());
                                },
                                ctas: [
                                    {
                                        label: t('modals.updateProfile.labels.gotIt'),
                                        variant: 'primary',
                                        onClick: () => {
                                            dispatch(closeModal({}));
                                            dispatch(accountGetAllCreditCardsRoutine.trigger());
                                        }
                                    }
                                ]
                            })
                        );

                        onDefaultCardChanged(newPrimaryCard);
                    },
                    onFailure: () => {
                        dispatch(
                            openModal({
                                showClose: true,
                                type: 'danger',
                                size: 'lg',
                                headerContent: (
                                    <BirdiModalHeaderDanger icon="alert" headerText={t('modals.updateProfile.error')} />
                                ),
                                bodyContent: (
                                    <FailureUpdateProfileModalContent area={t('modals.updateProfile.areas.payment')} />
                                ),
                                ctas: [
                                    {
                                        label: t('modals.updateProfile.labels.gotIt'),
                                        variant: 'primary',
                                        onClick: () => {
                                            dispatch(closeModal({}));
                                        }
                                    }
                                ]
                            })
                        );
                    }
                })
            );
        },
        [dispatch, onDefaultCardChanged, t]
    );

    const removeCreditCard = React.useCallback(
        (cardToRemove: CreditCardPayload) => {
            dispatch(
                accountRemoveCreditCardRoutine.trigger({
                    removalItem: cardToRemove,
                    onSuccess: (data: CreditCardPayload[]) => {
                        if (cardToRemove?.defaultCard) {
                            // DRX869 - if the user deletes the primary card, select the next card on the list as the primary
                            // The returned data still contains the removed item, so we can't just pull the first item
                            const newPrimaryCard = data.find(
                                (payment: CreditCardPayload) =>
                                    payment.cardActive === true && payment.defaultCard !== true
                            );

                            if (newPrimaryCard) {
                                updatePrimaryCreditCard(newPrimaryCard, { showPrimaryPaymentUpdatedModal: true });
                            }
                        }

                        onCardRemoved(cardToRemove);
                    }
                })
            );
        },
        [dispatch, onCardRemoved, updatePrimaryCreditCard]
    );

    const handleAddNewPaymentClick = React.useCallback<NonNullable<ButtonProps['onClick']>>(() => {
        dispatch(
            openModal({
                showClose: true,
                contentClassName: 'prescription-add-payment-modal',
                bodyContent: (
                    <BirdiModalContent
                        icon={'none'}
                        title={t('modals.addPaymentModal.title')}
                        body={
                            <PaymentForm
                                centerFormSubmit={true}
                                isInModal={true}
                                onCancel={() => {
                                    dispatch(closeModal({}));
                                }}
                            />
                        }
                    />
                ),
                ctas: []
            })
        );
    }, [dispatch, t]);

    const handleRemoveCardLinkClick = React.useCallback(
        (cardToRemove: CreditCardPayload): PaymentCardProps['onRemoveCardLinkClick'] =>
            () => {
                if (!cardToRemove) {
                    return;
                }

                dispatch(
                    openModal({
                        showClose: true,
                        type: 'danger',
                        size: 'lg',
                        headerContent: (
                            <BirdiModalHeaderDanger headerText={t('modals.removePaymentModal.title')} icon="alert" />
                        ),
                        bodyContent: (
                            <BirdiModalContentAlt
                                subTitle={t('modals.removePaymentModal.body', {
                                    paymentMethod: `<strong>${t('pages.profile.payment.cardTypeAndNum', {
                                        cardNumber: cardToRemove.secureCardNumber.slice(-4),
                                        cardType: cardToRemove.cardType
                                    })}</strong>`
                                })}
                            />
                        ),
                        ctas: [
                            {
                                onClick: () => {
                                    removeCreditCard(cardToRemove);
                                    dispatch(closeModal({}));
                                },
                                label: 'Continue',
                                variant: 'primary'
                            },
                            {
                                onClick: () => {
                                    dispatch(closeModal({}));
                                },
                                label: 'Cancel',
                                variant: 'ghost'
                            }
                        ]
                    })
                );
            },
        [dispatch, removeCreditCard, t]
    );

    const handleSelectCardRadioInputChange = React.useCallback(
        (card: CreditCardPayload): PaymentCardProps['onSelectCardRadioInputChange'] =>
            () => {
                onCardSelectionChange(card);
            },
        [onCardSelectionChange]
    );

    const handleSetDefaultClick = React.useCallback(
        (card: CreditCardPayload): PaymentCardProps['onSetDefaultClick'] =>
            () => {
                updatePrimaryCreditCard(card);
            },
        [updatePrimaryCreditCard]
    );

    const hasPaymentData = paymentData.length > 0;

    React.useEffect(() => {
        if (width < 1350) {
            isInnerWidthViewportRef.current = true;
        } else {
            isInnerWidthViewportRef.current = false;
        }
    }, [width]);

    return (
        <Container fluid className="mt-4">
            <Row xs={1} md={1} lg={2} xl={2} className="gy-3 d-flex">
                {hasPaymentData &&
                    paymentData
                        .filter((filterCard) => filterCard.cardActive && filterCard.cardIsExpired !== true)
                        .map((card, index) => {
                            return (
                                <PaymentCard
                                    cardHolder={card.cardName}
                                    cardType={card.cardType}
                                    endingDigits={card.secureCardNumber.slice(-4)}
                                    expiryMonth={card.cardMonthNum}
                                    expiryYear={card.cardYear.slice(-2)}
                                    isDefaultCard={Boolean(card.defaultCard)}
                                    isSelectCardRadioInputChecked={
                                        !!selectedCardSeqNum && card.cardSeqNum === selectedCardSeqNum
                                    }
                                    key={`payment-card-${card.secureCardNumber}-${index}`}
                                    onRemoveCardLinkClick={handleRemoveCardLinkClick(card)}
                                    onSelectCardRadioInputChange={handleSelectCardRadioInputChange(card)}
                                    onSetDefaultClick={handleSetDefaultClick(card)}
                                    removeCardLinkClassName={removeCardLinkClassName}
                                    showLabel={showLabel}
                                    showRemoveCardLink={showRemoveCardLink}
                                    showSelectCardRadioInput={showSelectCardRadioInput}
                                    showSetDefaultLink={showSetDefaultLink}
                                    isInnerWidthViewport={isInnerWidthViewportRef}
                                />
                            );
                        })}
            </Row>
            {addNewPaymentButtonLabel && !isProfile && (
                <Row>
                    <Col>
                        <Button
                            plusIcon
                            IconType="secondary"
                            className={`${buttonVariant === 'primary' ? 'sm-full' : 'p-0'}`}
                            dataGAFormName="Payments"
                            label={addNewPaymentButtonLabel}
                            onClick={handleAddNewPaymentClick}
                            type="button"
                            variant={buttonVariant}
                        />
                    </Col>
                </Row>
            )}
            {addNewPaymentButtonLabel && isProfile && (
                <Row>
                    <Col>
                        <Button
                            className={`${buttonVariant === 'primary' ? 'sm-full' : 'p-0'}`}
                            dataGAFormName="Payments"
                            label={addNewPaymentButtonLabel}
                            onClick={handleAddNewPaymentClick}
                            type="button"
                            variant={buttonVariant}
                        />
                    </Col>
                </Row>
            )}
            {!hasPaymentData && paymentRequiredMessage && (
                <Row>
                    <Col>
                        <div className="error mt-3">{paymentRequiredMessage}</div>
                    </Col>
                </Row>
            )}
        </Container>
    );
};

export default PaymentMethods;
