import React, { useCallback, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import { FailureUpdateCartModalContent } from 'pages/secure/cart/intra-page-items/_cart-update-modal-item';
import BirdiPrice from 'components/birdi-price/birdi-price.component';
import Disclaimer from 'components/disclaimer/disclaimer.component';
import AutoRefillBadge from 'components/new-medicine-cabinet-cart/prescription-information/auto-refill-badge/auto-refill-badge';

import { formatPrice } from 'schema/price.schema';
import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';
import { cancelOrderLine, getCartRoutine } from 'state/cart/cart.routines';
import { ExtendedRefillRxs } from 'types/order-prescription';
import { PrescriptionInformationProps } from '../types';

import { EXPEDITED_SHIPPING_COST, hasAdjudicatedPrice } from 'util/cart';
import { TrackCheckoutStep } from 'util/google_optimize/optimize_helper';
import { safeParseFloat } from 'util/number';
import Button from 'ui-kit/button/button';
import Spinner from 'ui-kit/spinner/spinner';

import './prescription-information.style.scss';

export const PrescriptionInformation: React.FC<PrescriptionInformationProps> = ({
    t,
    fullCart,
    prescriptions,
    accountHasInsurance,
    setCartPageTracked,
    orderConfirmation = false
}) => {
    const dispatch = useDispatch();
    const [isRemoveBusy, setIsRemoveBusy] = useState(false);
    const cartItems = fullCart.extendedRefillRxs;
    function formatBirdiSavings(item: ExtendedRefillRxs | undefined) {
        if (item) {
            const savingsAsDollar = safeParseFloat(item.awpPrice) - safeParseFloat(item.birdiPrice);
            return `${formatPrice(savingsAsDollar)}`;
        } else {
            return '';
        }
    }

    const showCartError = useCallback(() => {
        dispatch(
            openModal({
                showClose: true,
                className: 'prescription-modal',
                bodyContent: <FailureUpdateCartModalContent area={t('modals.updateCart.areas.cart')} />,
                ctas: [
                    {
                        label: t('modals.updateCart.labels.gotIt'),
                        variant: 'primary',
                        onClick: () => {
                            dispatch(closeModal({}));
                        },
                        dataGALocation: 'UpdateCartError'
                    }
                ]
            })
        );
    }, [dispatch, t]);

    return (
        <>
            <Spinner isVisible={isRemoveBusy} t={t} />
            <Container className="cart-prescription" fluid>
                {cartItems?.map((item) => {
                    const currentPrescription = prescriptions.find((obj) => {
                        return obj.rxNumber === item.rxNumber;
                    });

                    return (
                        <Row key={item.rxNumber} className="mt-4 pb-2 pb-md-4 cart-prescription--item">
                            <Col xs={7} sm={6} md={8} className="d-flex align-items-stretch flex-column">
                                <h3 className="cart-prescription--header">
                                    {currentPrescription?.dispensedProductName}
                                </h3>
                                <div className="cart-prescription--product-info">
                                    <div className="cart-prescription--product-info--rx-number">
                                        <b>Rx #{item.rxNumber}</b>
                                    </div>
                                    {currentPrescription?.fillQuantity && (
                                        <div className="cart-prescription--product-info--content">
                                            {t('pages.cart.quantity', {
                                                fillQuantity: currentPrescription?.fillQuantity
                                            })}
                                        </div>
                                    )}
                                    {(currentPrescription?.dispensedProductStrength ||
                                        currentPrescription?.dispensedProductUnitOfMeasure) && (
                                        <div className="cart-prescription--product-info--content">
                                            {currentPrescription?.dispensedProductStrength}
                                            {currentPrescription?.dispensedProductUnitOfMeasure}
                                        </div>
                                    )}
                                    {currentPrescription?.dispensedProductDosageForm && (
                                        <div className="cart-prescription--product-info--content">
                                            {currentPrescription?.dispensedProductDosageForm}
                                        </div>
                                    )}
                                </div>
                                {currentPrescription?.AutoRefillEnabled && (
                                    <div className="cart-prescription--auto-refill-label">
                                        <span>{t('pages.cart.rxSetToAutoRefill')}</span>
                                    </div>
                                )}

                                {currentPrescription?.autoRefillEnabled && (
                                    <div className="mt-1">
                                        <AutoRefillBadge
                                            text={t('components.medicineCabinetCart.prescriptionInfo.autoRefill')}
                                        />
                                    </div>
                                )}
                            </Col>
                            <Col xs={5} sm={6} md={4} className="text-right text-lg-right">
                                <div className="d-flex flex-column">
                                    {!item.hasKnownPrice && (
                                        <div className="mb-3">{t('pages.cart.priceCurrentlyUnavailable')}</div>
                                    )}
                                    {(hasAdjudicatedPrice(item, currentPrescription) ||
                                        (!hasAdjudicatedPrice(item, currentPrescription) &&
                                            item.hasKnownPrice &&
                                            item.showBirdiPricing)) && (
                                        <>
                                            <div className="mb-3">
                                                <div>
                                                    <h3 className="cart-total--title">
                                                        <span>
                                                            {formatPrice(
                                                                item.isUsingBirdiPrice
                                                                    ? item.birdiPrice
                                                                    : item.patientCopay
                                                            )}
                                                        </span>
                                                    </h3>
                                                </div>
                                                <div className="mb-3">
                                                    <div className="h6 cart-total--description mr-0 d-flex justify-content-end">
                                                        <BirdiPrice
                                                            accountHasInsurance={accountHasInsurance}
                                                            insuredText={t('pages.cart.rxItemTotal')}
                                                            showIfInsured={item.showBirdiPricing}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                    {!hasAdjudicatedPrice(item, currentPrescription) && item.hasKnownPrice && (
                                        <>
                                            {item.showStrikeThruPricing && (
                                                <>
                                                    <div className="mb-3">
                                                        <div>
                                                            <div className="cart-total--strikethrough">
                                                                <span className="text-decoration-line-through">
                                                                    {formatPrice(item.awpPrice)}
                                                                </span>
                                                            </div>
                                                            <h3 className="cart-total--title mb-0">
                                                                {formatPrice(item?.birdiPrice)}
                                                            </h3>
                                                            <div className="cart-total d-flex justify-content-end">
                                                                <BirdiPrice
                                                                    accountHasInsurance={accountHasInsurance}
                                                                    insuredText={t('pages.cart.rxItemTotal')}
                                                                    showIfInsured={true}
                                                                />
                                                            </div>
                                                            <div className="cart-total mt-2">
                                                                <span>
                                                                    {`${t('pages.cart.youSave')}: ${formatBirdiSavings(
                                                                        item
                                                                    )}`}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        </>
                                    )}
                                    <div className="mt-2 mb-2 mt-lg-0 d-flex justify-content-end">
                                        <Button
                                            type="button"
                                            variant="text"
                                            className="p-0 mb-3 left-n1 ml-lg-auto btn-remove text-capitalize btn-underline no-min-width"
                                            label={t('pages.cart.remove')}
                                            disabled={isRemoveBusy}
                                            onClick={() => {
                                                setIsRemoveBusy(true);
                                                setCartPageTracked(false);
                                                dispatch(
                                                    cancelOrderLine({
                                                        rxNumber: item.rxNumber,
                                                        onSuccess: () => {
                                                            setIsRemoveBusy(false);
                                                            TrackCheckoutStep({
                                                                stepName: 'remove',
                                                                step: '1',
                                                                cart: fullCart,
                                                                prescriptions: prescriptions,
                                                                t: t,
                                                                shippingCost: fullCart?.orderHeader?.orderHighPriority
                                                                    ? `${EXPEDITED_SHIPPING_COST}`
                                                                    : '0',
                                                                accountHasInsurance: accountHasInsurance
                                                            });
                                                            if (cartItems.length <= 1) {
                                                                dispatch(getCartRoutine.trigger());
                                                            }
                                                        },
                                                        onFailure: () => {
                                                            showCartError();
                                                            setIsRemoveBusy(false);
                                                        }
                                                    })
                                                );
                                            }}
                                            dataGAFormName="addMorePrescriptions"
                                        />
                                    </div>
                                </div>
                            </Col>
                            {!item.hasKnownPrice && (
                                <Col className="mt-3" sm={12} lg={'auto'}>
                                    <Disclaimer
                                        disclaimerText={t(
                                            accountHasInsurance
                                                ? 'pages.cart.rxItemErrorMessage'
                                                : 'pages.cart.rxItemErrorMessageDiscount'
                                        )}
                                    />
                                </Col>
                            )}
                            {item.showDisclaimer && item.disclaimerTranslationKey && (
                                <Col className="mt-3" sm={12} lg={'auto'}>
                                    <Disclaimer disclaimerText={t(item.disclaimerTranslationKey)} />
                                </Col>
                            )}
                        </Row>
                    );
                })}
            </Container>
        </>
    );
};
